<script setup lang="ts">
import { defineProps, defineEmits, toRefs, withDefaults } from "vue";
import ClearBtn from "@/components/elements/buttons/ClearBtn.vue";

const props = withDefaults(
  defineProps<{
    open: boolean;
    customClass?: string;
    maxWidth?: string;
  }>(),
  {
    maxWidth: "573px",
  }
);

const { open, maxWidth } = toRefs(props);

const $emit = defineEmits(["close"]);

const onHandleClose = (event: MouseEvent) => {
  if (event.target === event.currentTarget) {
    $emit("close");
  }
};
</script>

<template>
  <Teleport to="body">
    <div
      v-if="open"
      class="modal-container"
      :class="customClass"
      @click="onHandleClose"
    >
      <div
        class="modal"
        :style="{
          maxWidth: maxWidth,
        }"
      >
        <slot></slot>
        <ClearBtn @clear="$emit('close')" class="modal-close" />
      </div>
    </div>
  </Teleport>
</template>

<style scoped>
.modal-container {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
}

.modal {
  position: fixed;
  z-index: 999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
  background-color: #fff;
  padding: 60px 24px;
  max-height: 520px;
  border-radius: 7px;
  overflow-y: auto;
}

.modal-close {
  position: absolute;
  top: 24px;
  right: 24px;
  cursor: pointer;
  padding: 0;
}
</style>
