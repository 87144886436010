<template>
  <div class="product product-2 recomendation-card">
    <figure class="product-media" id="category_media">
      <span v-if="labels.new" class="product-label label-circle label-new"
        >Новинка</span
      >
      <span v-if="labels.offer" class="product-label label-circle label-sale"
        >Выгодно</span
      >
      <span v-if="labels.top" class="product-label label-circle label-top"
        >Топ</span
      >
      <router-link
        :to="{ name: 'Good', params: { id: product.id } }"
        class="top_products ozon_product"
      >
        <img :src="`${product.image}`" alt="Product" class="product-image" />
      </router-link>
    </figure>
    <div class="product-body flex-1">
      <div class="product-cat">
        <span v-for="(category, index) of product.categories" :key="index">
          <router-link :to="{ name: 'Good', params: { id: product.id } }">{{
            category.name
          }}</router-link>
          {{ index < product.categories.length - 1 ? "," : "" }}
        </span>
      </div>
      <h3 class="product-title">
        <router-link :to="{ name: 'Good', params: { id: product.id } }">{{
          product.name
        }}</router-link>
      </h3>
      <div class="product-price">
        <PriceFormatted :price="product.price ?? 0" price-name="бонусов" />
      </div>

      <div class="mb-4"></div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import PriceFormatted from "@/components/elements/typography/PriceFormatted.vue";
import { CommonCategory } from "@/api/models/CommonCategory";
import { GoodProduct } from "@/api/models/GoodProduct";
import { toRef, defineProps } from "vue";

const props = defineProps<{
  product: GoodProduct & { categories: CommonCategory[] };
  labels: {
    top: boolean;
    new: boolean;
    offer: boolean;
  };
}>();

const product = toRef(props.product);
const labels = toRef(props.labels);
</script>
<style scoped>
#category_carousel .top_products,
.ozon_product {
  display: flex !important;
  flex-direction: column !important;
  width: 100% !important;
}
.ozon_product img {
  position: absolute;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 180px;
}
#category_carousel {
  width: 100%;
}
@media screen and (max-width: 567px) {
  .product-title {
    text-overflow: ellipsis;
  }

  #category_carousel {
    min-height: 318.15px;
    width: 100%;
  }
  #popular_products {
    min-height: 318px;
  }
}
@media (min-width: 568px) and (max-width: 768px) {
  #category_carousel .top_products img,
  .ozon_product img {
    max-width: 150px;
  }
}
@media (min-width: 0px) and (max-width: 480px) {
  #category_carousel:first-child {
    min-width: 175px;
  }
  #category_carousel .top_products img,
  .ozon_product img {
    max-width: 110px;
    max-height: 150px;
    height: auto;
  }
}
@media (min-width: 0px) {
  #category_carousel .product-title {
    width: 100%;
    overflow: hidden;
  }
}

.recomendation-card {
  max-height: 416px;
}

.recomendation-card .product-media {
  max-height: none;
}

.recomendation-card .ozon_product img {
  max-width: 220px;
}
</style>
