import numeral from "numeral";

const generateRandomNumber = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

numeral.register("locale", "custom", {
  delimiters: {
    thousands: " ",
    decimal: ".",
  },
  abbreviations: {
    thousand: "k",
    million: "m",
    billion: "b",
    trillion: "t",
  },
  ordinal: function (number) {
    return number === 1 ? "er" : "ème";
  },
  currency: {
    symbol: "$",
  },
});

numeral.locale("custom");

const isNumber = (event: KeyboardEvent) => {
  const charCode = event.which ? event.which : event.keyCode;
  if (
    charCode > 31 &&
    (charCode < 48 || charCode > 57) &&
    charCode !== 43 &&
    charCode !== 40 &&
    charCode !== 41 &&
    charCode !== 32 &&
    charCode !== 45
  ) {
    event.preventDefault();
  }
};

const isNumberStrict = (event: KeyboardEvent) => {
  const char = String.fromCharCode(event.which);
  if (
    !/[0-9]/.test(char) ||
    (event.target as HTMLInputElement).value.length >= 11
  ) {
    event.preventDefault();
  }
};

export { generateRandomNumber, numeral, isNumber, isNumberStrict };
