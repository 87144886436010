<template>
  <label>
    <span v-if="hint" class="pass-input-label new-pass-input-label">
      {{ hint }}
    </span>
    <div class="new-pass-input-wrap">
      <input
        class="password-input"
        name="password repeat"
        :class="{ 'input--invalid': !isValidPassword }"
        :type="passwordFieldType"
        :placeholder="placeholder"
        :value="modalValue"
        autocomplete="password"
        @input="updateValue"
        @blur.stop="(e) => showEye(e.target as HTMLInputElement)"
        @input.stop="(e) => showEye(e.target as HTMLInputElement)"
      />
      <button
        class="new-pass-eye"
        :class="{ 'show-password--active': passwordFieldType === 'text' }"
        type="button"
        @mousedown="showPassword"
        @mouseup="hidePassword"
        @mouseleave="hidePassword"
        @touchstart="showPassword"
        @touchend="hidePassword"
      >
        <EyeIcon class="new-pass-eye__eye" />
        <EyeCloseIcon class="new-pass-eye__eye-close" />
      </button>
    </div>
    <div
      v-if="enabledValidation && !isValidPassword && modalValue"
      class="password-error"
    >
      Недостаточно сложный пароль.
    </div>
  </label>
</template>

<script setup lang="ts">
import { defineProps, toRefs, defineEmits, ref, withDefaults } from "vue";
import EyeIcon from "@/assets/images/icons/iconsComp/EyeIcon.vue";
import EyeCloseIcon from "@/assets/images/icons/iconsComp/EyeCloseIcon.vue";

const props = withDefaults(
  defineProps<{
    hint?: string;
    placeholder: string;
    modalValue: string;
    isValidPassword: boolean;
    enabledValidation?: boolean;
  }>(),
  {
    enabledValidation: true,
  }
);

const passwordFieldType = ref("password");

const showPassword = () => {
  passwordFieldType.value = "text";
};

const hidePassword = () => {
  passwordFieldType.value = "password";
};

const $emit = defineEmits(["update:modalValue"]);
const { hint, modalValue, placeholder } = toRefs(props);

const updateValue = (event: Event) => {
  const input = event.target as HTMLInputElement;
  const filteredValue = input.value.replace(/[а-яА-ЯёЁ]/g, "");
  input.value = filteredValue;
  $emit("update:modalValue", filteredValue);
};

const showEye = (target: HTMLInputElement) => {
  const length = target.value.length;
  const check = length > 0;
  target.classList.toggle("input--active", check);
};
</script>

<style scoped>
.pass-container {
  max-width: fit-content;
  margin-inline: auto;
}
.pass-wrap {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
}
.pass-wrap h2 {
  margin-bottom: 40px;
  font-size: 24px;
  font-family: Gilroy-Medium !important;
  font-weight: 500;
  line-height: 100%;
  letter-spacing: 0;
  color: var(--text_color);
}
.pass-wrap label {
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
}
.pass-wrap label:not(:last-child) {
  margin-bottom: 16px;
}
.pass-wrap input {
  height: 40px;
  width: 100%;
  padding: 12px 16px;
  border-radius: var(--bdrs-7);
  border: var(--border-grey);
  font-size: 16px;
  font-family: Gilroy-Regular !important;
  font-weight: 400;
  line-height: 100%;
  color: var(--text_color);
}
.pass-wrap input::placeholder {
  font-size: 16px;
  font-family: Gilroy-Regular !important;
  font-weight: 400;
  line-height: 100%;
  color: var(--grey-79);
  transition: outline 0.2s;
}
.pass-wrap input:focus {
  outline: 1px solid var(--primary_bg);
}
.pass-input-label {
  text-align: start;
  margin-bottom: 4px;
  font-size: 12px;
  font-family: Gilroy-Regular !important;
  font-weight: 400;
  line-height: 100%;
  color: var(--grey-79);
}
.pass-btn {
  display: block;
  width: 100%;
  margin-top: 32px;
  padding: 12px 16px;
  border-radius: var(--bdrs-7);
  border: 1px solid var(--primary_bg);
  font-size: 16px;
  font-family: Gilroy-Medium !important;
  font-weight: 500;
  line-height: 100%;
  color: var(--primary_bg);
  background-color: #fff;
  transition: background-color 0.2s, color 0.2s;
}
.pass-btn:hover {
  background-color: var(--primary_bg);
  color: #fff;
}

.new-pass-wrap {
  margin-block: 52px;
  max-width: 282px;
}
.new-pass-wrap h2 {
  margin-bottom: 32px;
  max-width: 255px;
}
.new-pass-wrap label {
  position: relative;
}
.new-pass-wrap input {
  padding-right: 40px;
}
.new-pass-input-wrap {
  position: relative;
  width: 100%;
}
.new-pass-eye {
  position: absolute;
  top: 50%;
  right: 15px;
  display: block;
  padding: 0;
  transform: translateY(-50%);
}
.new-pass-input-label {
  letter-spacing: -0.2px;
}
.new-pass-btn {
  margin-top: 31px;
}
input.input--active + .new-pass-eye {
  display: flex;
}
.new-pass-eye__eye-close,
.new-pass-input-wrap .new-pass-eye.show-password--active .new-pass-eye__eye {
  display: none;
}
.new-pass-input-wrap
  .new-pass-eye.show-password--active
  .new-pass-eye__eye-close {
  display: block;
}
input.input--active {
  background-color: var(--light_gray);
}
/* поле для пин-кода  */
.new-pass-inputs {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  margin-bottom: 32px;
}
.new-pass-inputs input.new-pass-input {
  padding-inline: 12px;
  text-align: center;
}
.new-pass-input {
  width: 38px;
}

.password-input {
  background: var(--light_gray);
}

.password-input.input--invalid {
  border-color: #ff6666;
}

.password-error {
  color: #ff6666;
  margin-top: 8px;
  font-size: 12px;
  line-height: 100%;
}
</style>
