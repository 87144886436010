import { defineStore } from "pinia";

type CalendarState = {
  showCalendar: boolean;
};

export const useCalendarStore = defineStore({
  id: "calendar",
  state: (): CalendarState => ({
    showCalendar: false,
  }),
  getters: {
    shCalendar: (state) => state.showCalendar,
  },
  actions: {
    setCalendar(state: CalendarState, value: boolean) {
      state.showCalendar = value;
    },
    toggleCalendar() {
      this.showCalendar = !this.showCalendar;
    },
  },
});
