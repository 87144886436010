<template>
  <label>
    <div class="phone-input-wrap">
      <input
        ref="phoneInput"
        name="phone"
        type="tel"
        placeholder="+7(___)___-__-__"
        class="iphone-only"
        :class="{ 'input--invalid': !isValidPhone }"
        :value="modelValue"
        @input="updateValue"
        @keypress="isNumber"
        maxlength="16"
      />
      <ClearBtn @clear.self="clearInput" class="phone-input-clear" />
    </div>
    <span
      v-if="title"
      class="pass-input-label remind-pass-input-label text-left"
    >
      {{ title }}
    </span>
  </label>
</template>

<script setup lang="ts">
import { defineProps, toRefs, defineEmits, ref, onMounted } from "vue";
import ClearBtn from "@/components/elements/buttons/ClearBtn.vue";
import { isNumber } from "@/utilities/number";
import IMask from "imask";

const props = defineProps<{
  modelValue: string;
  isValidPhone: boolean;
  title?: string;
}>();

const phoneInput = ref<HTMLInputElement | null>(null);

const $emit = defineEmits(["update:modelValue"]);

const { title, modelValue } = toRefs(props);

const updateValue = (event: Event) => {
  const input = event.target as HTMLInputElement;
  if (!input.value.startsWith("+7")) {
    const isEmpty = input.value === "" || input.value === "+";
    if (isEmpty) {
      input.value = "+7";
    } else {
      input.value = "+7" + input.value.replace(/^\+7/, "");
    }
  }
  input.value = "+7" + input.value.replace(/[^\d]/g, "").replace(/^7/, "");
  $emit("update:modelValue", input.value);
};

const clearInput = () => {
  $emit("update:modelValue", "+7");
};

onMounted(() => {
  if (phoneInput.value) {
    IMask(phoneInput.value, {
      mask: "+{7}(000)000-00-00",
    });
  }
});
</script>
<style scoped>
.remind-pass-input-label {
  color: var(--second-bg-btn);
  text-align: center;
  font-family: Gilroy;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  margin-top: 8px;
}

.phone-input-wrap {
  position: relative;
  width: 100%;
}

.phone-input-clear {
  position: absolute;
  top: 50%;
  right: 15px;
  display: block;
  padding: 0;
  transform: translateY(-50%);
  cursor: pointer;
}

.iphone-only {
  background: var(--light_gray);
}

.iphone-only.input--invalid {
  border-color: #ff6666;
}
</style>
