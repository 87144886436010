<template>
  <label>
    <span v-if="hint" class="pass-input-label pass-input-label_ls">
      {{ hint }}
    </span>
    <input
      class="number-member-input"
      :class="{ 'input--invalid': !isValidMemberNumber }"
      name="member"
      type="text"
      :placeholder="placeholder"
      :value="modelValue"
      @input="updateValue"
      @keypress="isNumberStrict"
      inputmode="numeric"
      pattern="[0-9]*"
      maxlength="11"
    />
    <span
      v-if="title"
      class="pass-input-label remind-pass-input-label text-left"
    >
      {{ title }}
    </span>
  </label>
</template>

<script setup lang="ts">
import { defineProps, toRefs, defineEmits } from "vue";
import { isNumberStrict } from "@/utilities/number";

const props = defineProps<{
  modelValue: string;
  title?: string;
  hint?: string;
  placeholder: string;
  isValidMemberNumber: boolean;
}>();

const $emit = defineEmits(["update:modelValue"]);

const { title, modelValue, placeholder, hint, isValidMemberNumber } =
  toRefs(props);

const updateValue = (event: Event) => {
  const input = event.target as HTMLInputElement;
  let sanitizedValue = input.value.replace(/[^0-9]/g, "");
  if (sanitizedValue.length > 11) {
    sanitizedValue = sanitizedValue.slice(0, 11);
  }
  $emit("update:modelValue", sanitizedValue);
};
</script>
<style scoped>
.pass-input-label {
  text-align: start;
  margin-bottom: 4px;
  font-size: 12px;
  font-family: Gilroy-Regular !important;
  font-weight: 400;
  line-height: 100%;
  color: var(--grey-79);
}

.remind-pass-input-label {
  margin-top: 8px;
}

.number-member-input {
  background: var(--light_gray);
}

.number-member-input.input--invalid {
  border-color: #ff6666;
}
</style>
