<template>
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17 21.5H7C4.8 21.5 3 19.7 3 17.5V7.5C3 5.3 4.8 3.5 7 3.5H17C19.2 3.5 21 5.3 21 7.5V17.5C21 19.7 19.2 21.5 17 21.5Z"
      stroke="#797979"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M1 9.5H5"
      stroke="#797979"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M1 15.5H5"
      stroke="#797979"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12 11.5C13.1046 11.5 14 10.6046 14 9.5C14 8.39543 13.1046 7.5 12 7.5C10.8954 7.5 10 8.39543 10 9.5C10 10.6046 10.8954 11.5 12 11.5Z"
      stroke="#797979"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8.5 17.5V17C8.5 16.05 8.9 15.15 9.5 14.5C10.15 13.85 11 13.5 12 13.5C13.95 13.5 15.5 15.05 15.5 17V17.5"
      stroke="#797979"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
