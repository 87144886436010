import { Train, TrainPlace, TrainSeats } from "@/api/models/Station";
import { CustomerData, UserData } from "@/repositories/types";
import { AviaSearch, HotelPassenger, Passenger, ProductHotel } from "@/types";
import { defineStore } from "pinia";
import { LocationQuery } from "vue-router";

export type OrderInformation = {
  fullName: string;
  email: string;
  phone: string;
  additionalPhone?: string;
  address?: string;
  postCode?: string;
  apartment?: string;
  deliveryAddress?: {
    id: number;
    name: string;
    price: string;
    estimatedDeliveryTime: number;
  }[];
  query: LocationQuery;
};

export type OrderTravelInformation = {
  fullName: string;
  email: string;
  phone: string;
  passengers: (Passenger & { type: "ADT" | "CHL" | "INF" })[];
  query: LocationQuery;
};

export type TrainOrder = TrainSeats & {
  trainInfo: Train;
  selectedSeats: TrainPlace[];
};

interface ApplicationState {
  isLoading: boolean;
  isSmallDisplay: boolean;
  user: UserData | null;
  customer: CustomerData | null;
  currentModal: "registration" | "login" | "recovery" | "remind" | null;
  isAuthenticated: boolean;
  auth: boolean;
  errorMessage: string | null;
  showToast: boolean;
  toastSettings: {
    type: "success" | "warning" | "error" | null;
    message: string | null;
  };
  order: OrderInformation | null;
  orderTravel: OrderTravelInformation | null;
  currentAvia: AviaSearch | null;
  currentTrain: TrainOrder | null;
  currentHotel: ProductHotel | null;
}

export const useApplicationStore = defineStore("application", {
  state: (): ApplicationState => ({
    isLoading: false,
    user: JSON.parse(localStorage.getItem("user") || "null"),
    customer: null,
    currentModal: null,
    isSmallDisplay: false,
    isAuthenticated: false,
    auth: Boolean(JSON.parse(localStorage.getItem("auth") || "null")),
    errorMessage: null,
    showToast: false,
    toastSettings: {
      type: null,
      message: null,
    },
    order: JSON.parse(localStorage.getItem("order") ?? "null"),
    orderTravel: JSON.parse(localStorage.getItem("orderTravel") ?? "null"),
    currentAvia: JSON.parse(localStorage.getItem("currentAvia") ?? "null"),
    currentTrain: JSON.parse(localStorage.getItem("currentTrain") ?? "null"),
    currentHotel: JSON.parse(localStorage.getItem("currentHotel") ?? "null"),
  }),
  actions: {
    showLoader() {
      this.isLoading = true;
    },
    hideLoader() {
      this.isLoading = false;
    },
    setUserData(user: UserData) {
      localStorage.setItem("user", JSON.stringify(user));
      this.user = user;
    },
    setCurrentModal(modal: ApplicationState["currentModal"]) {
      this.currentModal = modal;
    },
    setLogin() {
      localStorage.setItem("auth", "1");
      this.auth = true;
    },
    setLogout() {
      localStorage.removeItem("auth");
      this.auth = false;
      this.user = null;
    },
    updateIsMini() {
      this.isSmallDisplay = window.innerWidth < 1000;
    },
    setupHandlers() {
      window.addEventListener("resize", this.updateIsMini);
      this.updateIsMini();
    },
    isAuth() {
      const user = Boolean(JSON.parse(localStorage.getItem("auth") || "null"));
      return user;
    },
    getUser() {
      return this.user;
    },
    setErrorMessage(message: string | null) {
      this.errorMessage = message;
    },
    setCountBasket(qty: number) {
      if (this.user) {
        this.user.countBasket = qty;
        this.setUserData(this.user);
      }
    },
    getOrderInformation() {
      return this.order;
    },
    saveOrderInformation(order: OrderInformation) {
      this.order = order;
      localStorage.setItem("order", JSON.stringify(order));
    },
    getOrderTravelInformation() {
      return this.orderTravel;
    },
    getCurrentAvia() {
      return this.currentAvia;
    },
    getCurrentTrain() {
      return this.currentTrain;
    },
    getCurrentHotel() {
      return this.currentHotel;
    },
    saveOrderTravelInformation(order: OrderTravelInformation) {
      this.orderTravel = order;
      localStorage.setItem("orderTravel", JSON.stringify(order));
    },
    saveCurrentAvia(avia: AviaSearch) {
      this.currentAvia = avia;
      localStorage.setItem("currentAvia", JSON.stringify(avia));
    },
    saveCurrentTrain(train: TrainOrder) {
      this.currentTrain = train;
      localStorage.setItem("currentTrain", JSON.stringify(train));
    },
    saveCurrentHotel(hotel: ProductHotel) {
      this.currentHotel = hotel;
      localStorage.setItem("currentHotel", JSON.stringify(hotel));
    },
    hideToasts() {
      this.showToast = false;
    },
    toggleToast({
      type = null,
      message = null,
    }: {
      type?: "success" | "warning" | "error" | null;
      message?: string | null;
    }) {
      this.toastSettings = {
        type,
        message,
      };
      this.showToast = !this.showToast;
    },
  },
});
