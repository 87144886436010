<template>
  <div class="bg-light pt-5 pb-5 mb-2" style="padding-bottom: 66px !important">
    <div class="container trending-products">
      <div class="heading heading-flex mb-3">
        <div class="heading-left">
          <h2 class="title">Популярные товары</h2>
        </div>
        <div class="heading-right">
          <tabs
            class="nav nav-pills nav-border-anim justify-content-center"
            :data="tabsData"
            id="trendy"
          ></tabs>
        </div>
      </div>
      <div
        class="tab-content-goods tab-content-carousel just-action-icons-sm h-100"
      >
        <div class="tab-pane p-0 fade show active h-100" id="trendy-top-rated">
          <div class="swiper-carousel carousel-with-shadow swiper-1 h-100">
            <swiper
              :breakpoints="{
                '320': {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                '576': {
                  slidesPerView: 3,
                  spaceBetween: 20,
                },

                '768': {
                  slidesPerView: 4,
                  spaceBetween: 20,
                },
                '1200': {
                  slidesPerView: 5,
                },
                '1201': {
                  slidesPerView: 5,
                  spaceBetween: 35,
                },
              }"
              :modules="modules"
              :navigation="false"
              :slidesPerView="1"
              :spaceBetween="10"
              class="row h-100"
            >
              <swiper-slide
                class="good-card"
                v-for="(product, index) in allProducts"
                :key="index"
              >
                <GoodItemSlider
                  :currentIndex="index"
                  :product="product"
                  id="category_carousel"
                />
              </swiper-slide>
            </swiper>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ref, defineProps, toRef, computed } from "vue";
import Tabs from "../../elements/Tabs.vue";
import GoodItemSlider from "@/components/elements/goods/GoodItemSlider.vue";
import { Navigation, Pagination, Scrollbar } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/vue";
import { GoodProduct } from "@/api/models/GoodProduct";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Certificate } from "@/api/models/Certificate";
import { CommonCategory } from "@/api/models/CommonCategory";

const props = defineProps<{
  products: (GoodProduct & { categories: CommonCategory[] })[];
  certificate?: Certificate;
}>();
const products = toRef(props, "products");
const certificate = toRef(props, "certificate");
const allProducts = computed(() => {
  return [
    ...products.value
      .slice(0, 4)
      .map((product) => ({ ...product, type: "Good" })),
    { ...certificate.value, type: "Cert" },
  ].sort(() => 0.5 - Math.random());
});

const tabsData = ref([
  {
    id: "top-rated",
    title: "Все",
    url: "/shop/catalogue/goods?specialFilter=Popular",
    active: true,
  },
]);

const modules = [Navigation, Pagination, Scrollbar];
</script>
<style scoped>
.swiper-carousel {
  border-top-left-radius: 8px !important;
}

.swiper-carousel .swiper .swiper-wrapper .swiper-slide {
  width: 220px !important;
  margin-right: 17px !important;
}

@media screen and (max-width: 425px) {
  .swiper-carousel .swiper .swiper-wrapper .swiper-slide {
    width: 165px !important;
  }
}

.good-card {
  max-height: 360px !important;
}

.good-card .product-media img {
  max-height: auto !important;
}
</style>
