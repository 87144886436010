<template>
  <nav class="main-nav">
    <ul class="menu sf-arrows">
      <!-- :  -->
      <li class="megamenu-container" :class="{ active: currentRoute == '/' }">
        <router-link to="/" class="sf-with-ul">Главная</router-link>
      </li>
      <li>
        <router-link
          :to="{
            name: 'AdmitadsStatic',
          }"
          class="sf-with-ul"
          style="display: flex; gap: 10px"
          >Спецпредложения
          <img src="@/assets/newImg/icons/arrow_down.svg" alt="" />
        </router-link>
        <div class="megamenu megamenu-md">
          <div
            class="no-gutters"
            style="margin: 0 !important; display: unset !important"
          >
            <div class="col-md-8">
              <div class="menu-col">
                <div class="">
                  <div class="col-md-6">
                    <!-- <div class="menu-title">Shop with sidebar</div> -->
                    <ul>
                      <li v-for="category in categories" :key="category.id">
                        <router-link
                          :to="`/shop/catalogue/admitads?categoryIds=${category.id}`"
                        >
                          {{ category.name }}
                        </router-link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>
      <li>
        <a href="https://vamprivet.ru/" class="sf-with-ul" target="_blank"
          >Акции</a
        >
      </li>
    </ul>
  </nav>
</template>
<script lang="ts" setup>
import { computed, ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import { fetchCategories } from "@/repositories/admitads/categories";
import { CommonCategory } from "@/api/models/CommonCategory";

const route = useRoute();
const categories = ref<CommonCategory[]>([]);

const currentRoute = computed(() => route.path);

const fetchAdmitads = async () => {
  const { data } = await fetchCategories();
  categories.value = data;
};

onMounted(async () => {
  await fetchAdmitads();
});
</script>
