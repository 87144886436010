<template>
  <div
    :class="[
      'remind-pass-switch-wrap',
      modalValue === 'phone' ? 'remind-pass-switch-wrap_mb' : '',
      modalValue === 'code' ? 'remind-pass-switch-wrap_member' : '',
    ]"
  >
    <button
      :class="[
        'remind-pass-switch',
        modalValue === 'phone' ? 'remind-pass-switch_primary' : '',
      ]"
      type="button"
      @click="updateValue('phone')"
    >
      <PhoneBookIcon />
      <span>По номеру телефона</span>
    </button>

    <hr class="remind-pass-switch-divider" />

    <button
      :class="[
        'remind-pass-switch',
        modalValue === 'code' ? 'remind-pass-switch_primary' : '',
      ]"
      type="button"
      @click="updateValue('code')"
    >
      <UserBookIcon />
      <span>По номеру участника</span>
    </button>
  </div>
</template>

<script setup lang="ts">
import { defineProps, toRefs, defineEmits } from "vue";
import PhoneBookIcon from "@/assets/images/icons/iconsComp/PhoneBookIcon.vue";
import UserBookIcon from "@/assets/images/icons/iconsComp/UserBookIcon.vue";

type Tab = "phone" | "code";

const props = defineProps<{
  modalValue: Tab;
}>();

const { modalValue } = toRefs(props);
const $emit = defineEmits(["update:modalValue"]);

const updateValue = (val: Tab) => {
  $emit("update:modalValue", val);
};
</script>

<style scoped>
.remind-pass-switch-wrap {
  display: flex;
  gap: 13px;
  margin-bottom: 55px;
  height: 67px;
}

.remind-pass-switch-divider {
  height: 100%;
  width: 1px;
  margin: 0;
  background-color: var(--light_gray);
}

.remind-pass-switch-wrap {
  gap: 13px;
}

.remind-pass-switch-wrap_mb {
  margin-bottom: 51px;
}

.remind-pass-switch-wrap_mb {
  margin-bottom: 42px;
}

.remind-pass-switch-wrap_member {
  margin-bottom: 44px;
}

.remind-pass-switch-wrap {
  display: flex;
  gap: 13px;
  margin-bottom: 16px;
}

.remind-pass-switch {
  display: flex;
  align-items: center;
  gap: 9px;
  padding: 0;
  text-wrap: nowrap;
  font-size: 14px;
  font-family: Gilroy-Regular !important;
  font-weight: 400;
  line-height: 100%;
  color: var(--grey-79);
  transition: color 0.2s;
}

.remind-pass-switch_primary,
.remind-pass-switch:hover {
  color: var(--primary_bg);
}

@media (max-width: 480px) {
  .new-pass-wrap {
    max-width: 100%;
    padding-inline: 16px;
  }

  .new-pass-inputs {
    gap: 11px;
    max-width: 286px;
    margin-inline: auto;
    justify-content: initial;
  }

  .remind-pass-switch {
    flex-direction: column;
  }

  .remind-pass-wrap h2 {
    font-size: 22px;
    margin-bottom: 30px;
  }

  .remind-pass-switch-wrap {
    gap: 32px;
  }

  .remind-pass-switch-wrap_mb {
    margin-bottom: 42px;
  }

  .remind-pass-switch-wrap_member {
    margin-bottom: 44px;
  }

  .remind-pass-wrap form {
    max-width: 327px;
  }
}

.remind-pass-switch:hover svg path {
  transition: stroke 0.2s;
}

.remind-pass-switch_primary svg path,
.remind-pass-switch:hover svg path {
  stroke: var(--primary_bg);
}
</style>
