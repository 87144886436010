<template>
  <div class="p-area-widget w-100">
    <div class="p-area-widget-title text-left">
      <span>{{ errorMsg }}</span>
    </div>
    <ClearBtn @clear="clearErrorMsg" class="input-clear" />
  </div>
</template>

<script setup lang="ts">
import { defineProps, defineEmits, toRefs } from "vue";
import ClearBtn from "@/components/elements/buttons/ClearBtn.vue";

const props = defineProps<{
  errorMsg: string | null;
}>();
const { errorMsg } = toRefs(props);
const $emit = defineEmits(["clear"]);

const clearErrorMsg = () => {
  $emit("clear");
};
</script>

<style scoped>
.p-area-widget {
  display: flex;
  align-items: center;
  min-height: 40px;
  justify-content: space-between;
}

.input-clear {
  padding: 0 3px;
}
</style>
