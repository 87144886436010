<template>
  <!-- тут должна быть иконка перечеркнутого глаза -->
  <svg
    width="18"
    height="14"
    viewBox="0 0 18 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.5 7.00004C1.5 7.00004 4.25 1.58337 9 1.58337C13.75 1.58337 16.5 7.00004 16.5 7.00004C16.5 7.00004 13.75 12.4167 9 12.4167C4.25 12.4167 1.5 7.00004 1.5 7.00004Z"
      stroke="#797979"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.00065 8.66671C9.91732 8.66671 10.6673 7.91671 10.6673 7.00004C10.6673 6.08337 9.91732 5.33337 9.00065 5.33337C8.08398 5.33337 7.33398 6.08337 7.33398 7.00004C7.33398 7.91671 8.08398 8.66671 9.00065 8.66671Z"
      stroke="#797979"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <line
      x1="1"
      y1="1"
      x2="17"
      y2="13"
      stroke="black"
      stroke-width="1"
      stroke-linecap="round"
      paint-order="stroke fill"
    />
  </svg>
</template>
