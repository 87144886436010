<template>
  <div class="product product-2 d-flex flex-column">
    <figure id="category_media" class="product-media certificate-card">
      <template v-if="showLabel">
        <span class="product-label label-circle label-top">Топ</span>
      </template>
      <router-link :to="currentLink" class="top_products ozon_product">
        <img :src="product.image ?? ''" alt="Product" class="product-image" />
      </router-link>
    </figure>
    <div class="product-body">
      <div class="product-cat">
        <span v-for="category in categories" :key="category.id">
          <span v-if="category.id === 0">
            {{ category.name }}
          </span>
          <router-link
            v-else
            :to="{
              name: 'CertificatesStatic',
              query: { categoryIds: category.id },
            }"
          >
            {{ category.name }}
          </router-link>
        </span>
      </div>
      <h3 class="product-title">
        <router-link :to="currentLink">{{ product.name }}</router-link>
      </h3>
      <div
        v-if="product?.hasOwnProperty('price')"
        class="product-price"
        style="gap: 5px"
      >
        <span>от</span>
        <PriceFormatted :price="product.price ?? 0" price-name="бонусов" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, defineProps, toRef, withDefaults } from "vue";
import { Certificate } from "@/api/models/Certificate";
import { CommonCategory } from "@/api/models/CommonCategory";
import PriceFormatted from "@/components/elements/typography/PriceFormatted.vue";
import { useRoute } from "vue-router";

const props = withDefaults(
  defineProps<{
    product: Certificate & { categories: CommonCategory[] };
    showLabel?: boolean;
    pageType?: "Charities" | "Certificates";
  }>(),
  {
    showLabel: true,
    pageType: "Certificates",
  }
);

const product = toRef(props.product);
const route = useRoute();

const currentLink = computed(() => {
  return props.pageType === "Charities"
    ? {
        name: "Certificate",
        params: { id: product.value.id },
        query: {
          vendorId: product.value.vendorId,
          type: "charity",
        },
      }
    : {
        name: "Certificate",
        params: { id: product.value.id },
        query: {
          vendorId: product.value.vendorId,
          categoryIds: currentCategories.value,
        },
      };
});

const currentCategories = computed(() => {
  return (
    route.query.categoryIds ||
    product.value?.categories.map((category) => category.id).join(",")
  );
});

const categories = computed(() => {
  return product.value?.categories.length
    ? product.value?.categories.map((category) => ({
        id: category.id,
        name: category.name,
      }))
    : [
        {
          id: 0,
          name: "Подарочные сертификаты",
        },
      ];
});
</script>

<style scoped>
.certificate-card img {
  background: var(--light_gray);
  border: 1px solid #ebebeb;
}
</style>
