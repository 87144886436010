<template>
  <div :class="{ 'btn-outlined': outlined, 'btn-covered': !outlined }">
    <button
      :class="{ 'pass-btn--disabled': disabled }"
      :type="type"
      class="pass-btn"
      :disabled="disabled"
      @click="$emit('click')"
    >
      {{ title }}
    </button>
  </div>
</template>

<script setup lang="ts">
import { toRefs, defineProps, defineEmits } from "vue";

const props = defineProps<{
  title: string;
  type?: "submit" | "button";
  outlined?: boolean;
  disabled?: boolean;
}>();

const $emit = defineEmits(["click"]);

const { title, type, outlined } = toRefs(props);
</script>

<style lang="scss">
.pass-btn {
  display: block;
  width: 100%;
  border-radius: var(--bdrs-7);
  font-size: 16px;
  font-family: Gilroy-Medium !important;
  font-weight: 500;
  line-height: 100%;
  transition: background-color 0.2s, color 0.2s;
  height: 40px;
}

.btn-outlined {
  .pass-btn {
    border: 1px solid var(--primary-bg-btn);
    color: var(--primary-bg-btn);
    background-color: #fff;

    &.pass-btn--disabled {
      cursor: not-allowed;

      &:hover {
        border: 1px solid var(--primary-bg-btn);
        color: var(--primary-bg-btn);
        background-color: #fff;
      }
    }
  }

  .pass-btn:hover {
    background-color: var(--primary-bg-btn);
    color: #fff;
  }
}

.btn-covered {
  .pass-btn {
    background-color: var(--primary-bg-btn);
    color: #fff;

    &.pass-btn--disabled {
      background-color: var(--disabled-bg-btn) !important;
      cursor: not-allowed;
      color: #fff;

      &:hover {
        background-color: var(--disabled-bg-btn) !important;
        color: #fff !important;
      }
    }
  }

  .pass-btn:hover {
    border: 1px solid var(--primary-bg-btn);
    color: var(--primary-bg-btn);
    background-color: #fff;
  }

  .pass-btn:hover:not(.pass-btn--disabled) {
    border: 1px solid var(--disabled-bg-btn);
    color: var(--disabled-bg-btn);
    background-color: #fff;
  }
}
</style>
