<template>
  <div ref="menuContainer" class="container-truncate h-100">
    <div class="list-truncate" @click="$emit('close')">
      <ul>
        <li>
          <router-link class="link-truncate">
            <UserBlackIcon />
            <span>Профиль</span>
          </router-link>
        </li>
        <li>
          <router-link class="link-truncate">
            <FavoriteEmptyIcon />
            <span>Избранное</span>
          </router-link>
        </li>
        <li>
          <router-link
            class="link-truncate"
            :to="{
              name: 'AccountOrders',
            }"
          >
            <ListIcon />
            <span>Заказы</span>
          </router-link>
        </li>
        <li>
          <router-link
            class="link-truncate"
            :to="{
              name: 'Operations',
            }"
          >
            <OperationsIcon />
            <span>Операции</span>
          </router-link>
        </li>
        <li>
          <div class="link-truncate cursor-pointer" @click="onLogout">
            <ExitIcon />
            <span>Выход</span>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  defineProps,
  toRefs,
  defineEmits,
  onBeforeUnmount,
  onMounted,
  ref,
} from "vue";
import ExitIcon from "@/assets/images/icons/iconsComp/ExitIcon.vue";
import FavoriteEmptyIcon from "@/assets/images/icons/iconsComp/FavoriteEmptyIcon.vue";
import ListIcon from "@/assets/images/icons/iconsComp/ListIcon.vue";
import UserBlackIcon from "@/assets/images/icons/iconsComp/UserBlackIcon.vue";
import OperationsIcon from "@/assets/images/icons/iconsComp/OperationsIcon.vue";
import { useAuth } from "@/composables/useAuth";

// const props = defineProps<{}>();
const { onLogout } = useAuth();
const $emit = defineEmits(["close"]);
const menuContainer = ref<HTMLElement | null>(null);

// const {} = toRefs(props);

const handleClickOutside = (event: Event) => {
  const target = event.target as HTMLElement;
  if (
    menuContainer.value &&
    !menuContainer.value.contains(target) &&
    !target.classList.contains("text-truncate") &&
    !target.parentElement?.parentElement?.classList.contains("text-truncate")
  ) {
    $emit("close");
  }
};

onMounted(() => {
  document.addEventListener("click", handleClickOutside);
});

onBeforeUnmount(() => {
  document.removeEventListener("click", handleClickOutside);
});
</script>

<style scoped>
.header-right {
  overflow: visible;
}

.dropdown-toggle {
  justify-content: space-between;
  border-top-right-radius: 0.8rem;
  border-top-left-radius: 0.8rem;
}

.dropdown-menu:hover .category-dropdown .dropdown-toggle {
  color: #fff !important;
}

.hover-close {
  display: none;
}

.call {
  display: flex;
  gap: 5px;
  font-size: 14px;
  font-family: Gilroy-Medium !important;
}

.call:hover svg path {
  fill: var(--primary_bg);
}

.logIn {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
  font-family: Gilroy-Medium !important;
  color: #777777 !important;
}

.logIn:hover {
  fill: var(--primary_bg) !important;
  color: var(--primary_bg) !important;
}

.logIn:hover svg path {
  fill: var(--primary_bg);
}

.drop-custom {
  display: flex;
  align-items: center;
  font-family: Gilroy-Medium !important;
  gap: 10px;
}

.dropdown:hover .drop-custom {
  color: white;
}

.drop-custom:hover {
  color: #fff;
}

.category-dropdown {
  border-top-right-radius: 0.8rem;
  border-top-left-radius: 0.8rem;
}

.category-dropdown:hover {
  background-color: transparent !important;
  color: #fff !important;
}

.category-dropdown:hover svg path {
  fill: #fff;
}

.category-dropdown:hover .hover-menu {
  display: none;
}

.category-dropdown:hover .hover-close {
  display: block;
}

.container-truncate {
  position: relative;
}

.btn-truncate {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0;
  font-family: Gilroy-Medium !important;
  color: #000;
  transition: color 0.2s;
}

.btn-truncate:hover {
  color: var(--primary_bg);
}

.list-truncate {
  position: absolute;
  left: 53%;
  top: 50px;
  display: block;
  min-width: 265px;
  max-width: 265px;
  padding: 16px;
  background-color: #fff;
  border-radius: 7px;
  -webkit-box-shadow: 0px 1px 13px -2px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 0px 1px 13px -2px rgba(34, 60, 80, 0.2);
  box-shadow: 0px 1px 13px -2px rgba(34, 60, 80, 0.2);
  transition: opacity 0.3s;
  transform: translateX(-100%);
  animation: fadeIn 0.3s;
  z-index: 10000000;
}

.container-truncate::before {
  content: "";
  position: absolute;
  top: 41px;
  left: 53%;
  transform: rotateZ(45deg) translateX(-50%);
  display: none;
  height: 30px;
  width: 30px;
  border-radius: 4px;
  -webkit-box-shadow: 0px 1px 13px -2px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 0px 1px 13px -2px rgba(34, 60, 80, 0.2);
  box-shadow: 0px 1px 13px -2px rgba(34, 60, 80, 0.2);
  background-color: #fff;
  opacity: 0;
  animation: fadeIn 0.3s;
  transition: opacity 0.3s;
}

.container-truncate--active::before {
  display: block;
  opacity: 1;
}

.list-truncate::before {
  content: "";
  position: absolute;
  top: -45px;
  left: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
  z-index: -1;
}

.list-truncate::after {
  content: "";
  position: absolute;
  top: 2px;
  left: 50%;
  transform: rotateZ(45deg) translateX(-50%);
  height: 30px;
  width: 30px;
  border-radius: 7px;
  background-color: #fff;
  z-index: 1;
}

.list-truncate ul {
  margin: 0;
}

.list-truncate li:not(:last-child) {
  margin-bottom: 18px;
}

.list-truncate li svg {
  width: 20px;
}

.link-truncate {
  display: flex;
  align-items: center;
  gap: 16px;
  font-family: Gilroy-Regular !important;
  font-weight: 400;
  line-height: 100%;
  color: var(--text_color);
}

.link-truncate:hover {
  color: var(--primary_bg);
}

.mobile-menu-cos {
  width: 50px;
  height: 50px;
}
</style>

<style scoped>
.link-truncate svg path {
  transition: fill 0.2s, stroke 0.2s;
}

.link-truncate:hover svg path {
  stroke: var(--primary_bg);
}

.list-truncate li:nth-last-child(2):hover svg path {
  stroke: inherit;
  fill: var(--primary_bg);
}

@media (max-width: 992px) {
  .header-bottom.sticky-header {
    display: flex;
    height: 56px;
    border-top: var(--border-grey);
    border-bottom: none;
  }
  .header-bottom .header-left {
    display: none;
  }
  .header-bottom .header-right {
    margin-inline: auto;
  }
  .header-bottom .header-right .btn-truncate {
    font-family: Gilroy-Regular !important;
    font-weight: 400;
    color: var(--primary_bg);
  }
  .header-bottom .header-right svg path {
    stroke: var(--primary_bg);
  }
}
</style>
