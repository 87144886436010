<template>
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17 21.5H7C4.8 21.5 3 19.7 3 17.5V7.5C3 5.3 4.8 3.5 7 3.5H17C19.2 3.5 21 5.3 21 7.5V17.5C21 19.7 19.2 21.5 17 21.5Z"
      stroke="#797979"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M1 9.5H5"
      stroke="#797979"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M1 15.5H5"
      stroke="#797979"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.504 17.5C11.5554 16.7073 10.8621 15.7622 10.4243 14.7256M12.504 17.5C11.5189 16.6768 10.8256 15.7317 10.4243 14.7256M12.504 17.5L13.4527 17.5C13.7446 17.5 13.9635 17.3171 13.9635 17.0732L13.9635 15.6098C14 15.0915 13.5257 14.6951 12.9419 14.6951L10.4243 14.7256M10.4243 14.7256C9.87697 13.2927 9.84048 11.7378 10.4243 10.2744M10.4243 10.2744C10.8256 9.26829 11.5189 8.32317 12.504 7.5L13.4527 7.5C13.7446 7.5 14 7.71341 14 7.95732V9.42073C14 9.90854 13.5257 10.3049 12.9054 10.2744L10.4243 10.2744Z"
      stroke="#797979"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
