import { defineStore } from "pinia";

interface FlightState {
  modalShow: boolean;
}

export const useFlightStore = defineStore({
  id: "flight",
  state: (): FlightState => ({
    modalShow: false,
  }),
  getters: {
    showFilter: (state) => state.modalShow,
  },
  actions: {
    toggleFilter(state: FlightState) {
      const newModalShow = !state.modalShow;
      state.modalShow = newModalShow;
      return newModalShow;
    },
  },
});
