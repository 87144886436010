import { computed, ref } from "vue";
import validator from "validator";
import { useApplicationStore } from "@/store/application";
import { isValidPhoneNumber } from "@/utilities/phone";

export const useInput = (defaultTab: "phone" | "code" = "phone") => {
  const applicationStore = useApplicationStore();
  const currentTab = ref<"phone" | "code">(defaultTab);
  const phone = ref("+7");
  const additionalPhone = ref("+7");
  const memberNumber = ref("");
  const password = ref("");
  const repeatPassword = ref("");
  const email = ref("");
  const fullName = ref("");
  const address = ref("");
  const postCode = ref("Индекс");
  const apartment = ref("");
  const deliveryAddress = ref<
    {
      id: number;
      name: string;
      price: string;
      estimatedDeliveryTime: number;
    }[]
  >([]);

  const errorMessage = computed({
    get: () => applicationStore.errorMessage,
    set: (value) => {
      applicationStore.setErrorMessage(value);
    },
  });

  const clearInputs = () => {
    phone.value = "+7";
    memberNumber.value = "";
    repeatPassword.value = "";
    password.value = "";
    email.value = "";
    fullName.value = "";
    errorMessage.value = null;
  };

  const clearErrorMsg = () => {
    errorMessage.value = null;
  };

  const currentName = computed(() => {
    return currentTab.value === "phone" ? phone.value : memberNumber.value;
  });

  const isValidPassword = computed(() => {
    return password.value.length > 0
      ? validator.isStrongPassword(password.value, {
          minLength: 7,
          minLowercase: 1,
          minUppercase: 1,
          minNumbers: 1,
          minSymbols: 0,
        })
      : true;
  });

  const isValidRepeatPassword = computed(() => {
    return repeatPassword.value.length > 0
      ? validator.isStrongPassword(repeatPassword.value, {
          minLength: 7,
          minLowercase: 1,
          minUppercase: 1,
          minNumbers: 1,
          minSymbols: 0,
        })
      : true;
  });

  const isValidPhone = computed(() => {
    const cleanedPhone = phone.value.replace(/[\s()-]/g, "");

    return phone.value !== "+7" ? isValidPhoneNumber(cleanedPhone) : true;
  });

  const isValidAdditionalPhone = computed(() => {
    const cleanedPhone = additionalPhone.value.replace(/[\s()-]/g, "");

    return additionalPhone.value !== "+7"
      ? isValidPhoneNumber(cleanedPhone)
      : true;
  });

  const isValidMemberNumber = computed(() => {
    return memberNumber.value.length > 0
      ? validator.isLength(memberNumber.value, { min: 11, max: 11 })
      : true;
  });

  const isValidEmail = computed(() => {
    return email.value.length > 0
      ? validator.isEmail(email.value) && /^[a-zA-Z0-9@._-]*$/.test(email.value) // Проверка email и допустимых символов
      : true;
  });

  const isValidFullName = computed(() => {
    return fullName.value.length > 0 ? fullName.value.length >= 3 : true;
  });

  const isValidAddress = computed(() => {
    return address.value.length > 0 ? fullName.value.length >= 10 : true;
  });

  const isValidDeliveryAddress = computed(
    () =>
      deliveryAddress.value.length > 0 &&
      deliveryAddress.value.every((address) => address.name?.length > 0)
  );

  return {
    currentTab,
    currentName,
    memberNumber,
    phone,
    additionalPhone,
    email,
    postCode,
    fullName,
    apartment,
    deliveryAddress,
    address,
    errorMessage,
    password,
    repeatPassword,
    clearInputs,
    clearErrorMsg,
    isValidPhone,
    isValidPassword,
    isValidMemberNumber,
    isValidRepeatPassword,
    isValidEmail,
    isValidFullName,
    isValidAdditionalPhone,
    isValidAddress,
    isValidDeliveryAddress,
  };
};
