<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.5 14.1667L2.5 5.83333C2.5 4 4 2.5 5.83333 2.5L14.1667 2.5C16 2.5 17.5 4 17.5 5.83333L17.5 14.1667C17.5 16 16 17.5 14.1667 17.5L5.83333 17.5C4 17.5 2.5 16 2.5 14.1667Z"
      stroke="black"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5.83268 13.75L9.16602 13.75"
      stroke="black"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5.83398 6.25L10.834 6.25"
      stroke="black"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5.83268 10L14.166 10"
      stroke="black"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
