<template>
  <div
    class="header-search header-search-extended mr-0 header-search-visible d-none d-lg-block"
  >
    <span class="search-toggle">
      <i class="icon-search"></i>
    </span>
    <form
      action="#"
      method="get"
      @click.stop="showSearchForm"
      @submit.prevent="searchProducts"
    >
      <div class="header-search-wrapper search-wrapper-wide">
        <label for="q" class="sr-only">Search</label>
        <button class="ml-3" type="submit">
          <img src="../../../../assets/newImg/icons/search.svg" alt="" />
        </button>
        <input
          ref="searchInput"
          type="text"
          class="form-control"
          placeholder="Поиск по каталогу товаров"
          v-model="searchTerm"
          v-tooltip.focus="{
            value: inputTooltipMsg,
            class: 'custom-tooltip',
            disabled: false,
          }"
        />
      </div>
    </form>
  </div>
</template>
<script lang="ts" setup>
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";

const searchTerm = ref<string>("");
const searchInput = ref<HTMLInputElement | null>(null);
const inputTooltipMsg = ref("Пожалуйста, заполните это поле");
const router = useRouter();

const searchProducts = async () => {
  if (searchTerm.value.length < 2) {
    inputTooltipMsg.value = "Пожалуйста, введите минимум два символа";
    searchInput.value?.blur();
    searchInput.value?.focus();
  } else {
    await router.push({
      name: "GoodsSearch",
      query: { search: searchTerm.value },
    });
    searchTerm.value = "";
    searchInput.value?.blur();
  }
};

const showSearchForm = () => {
  document.querySelector(".header .header-search")?.classList.add("show");
};

const closeSearchForm = () => {
  document.querySelector(".header .header-search")?.classList.remove("show");
};

onMounted(() => {
  document.querySelector("body")?.addEventListener("click", closeSearchForm);
});
</script>
