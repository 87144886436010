<template>
  <svg
    width="18"
    height="15"
    viewBox="0 0 18 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.4173 7.66671L9.00065 14.0834L2.58398 7.66671C1.16732 6.25004 1.16732 3.83337 2.58398 2.41671C3.33398 1.66671 4.25065 1.33337 5.25065 1.33337C6.16732 1.33337 7.16732 1.66671 7.91732 2.41671L9.00065 3.58337L10.1673 2.41671C11.584 1.00004 14.0007 1.00004 15.4173 2.41671C16.834 3.91671 16.834 6.25004 15.4173 7.66671Z"
      stroke="black"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
