<template>
  <div class="container new new-arrivals">
    <div class="heading heading-flex mb-3">
      <div class="heading-left">
        <h2 class="title">Популярные сертификаты</h2>
      </div>
      <div class="heading-right">
        <tabs
          class="nav nav-pills nav-border-anim justify-content-center"
          :data="tabsData"
          id="new"
        ></tabs>
      </div>
    </div>
    <div class="tab-content tab-content-carousel just-action-icons-sm">
      <div class="tab-pane p-0 fade show active" id="new-all">
        <swiper
          :breakpoints="{
            '320': {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            '576': {
              slidesPerView: 3,
              spaceBetween: 20,
            },

            '768': {
              slidesPerView: 4,
              spaceBetween: 20,
            },
            '1200': {
              slidesPerView: 5,
            },
            '1201': {
              slidesPerView: 5,
              spaceBetween: 20,
            },
          }"
          :modules="modules"
          :navigation="false"
          :pagination="isMobile"
          :slidesPerView="1"
          :spaceBetween="10"
          class="row"
        >
          <swiper-slide
            class="certificate-card"
            v-for="(product, index) in products"
            :key="index"
          >
            <CertificateCard
              :product="product"
              class="mb-3"
              id="category_carousel"
            />
          </swiper-slide>
        </swiper>
      </div>
    </div>
  </div>
</template>
<script>
import Tabs from "../../elements/Tabs.vue";
import { catFilter } from "../../../utilities/common.js";
import { carouselSetting1 } from "../../../utilities/carousel.js";
import CertificateCard from "@/components/elements/certificates/CetificateCard.vue";
import { attrFilter } from "../../../utilities/common.js";
import { Navigation, Pagination, Scrollbar } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/vue";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
export default {
  props: {
    products: Array,
  },
  data: function () {
    return {
      tabsData: [
        {
          id: "all",
          title: "Все",
          url: "/shop/catalogue/certificates?specialFilter=Popular",
        },
      ],
      carouselSetting1: {
        ...carouselSetting1,
        slidesPerView: 5,
        navigation: {
          nextEl: ".new .swiper-1 .swiper-next",
          prevEl: ".new .swiper-1 .swiper-prev",
        },
        pagination: {
          el: ".new .swiper-1 .swiper-dots",
          clickable: true,
        },
      },
      carouselSetting2: {
        ...carouselSetting1,
        slidesPerView: 5,
        navigation: {
          nextEl: ".new .swiper-2 .swiper-next",
          prevEl: ".new .swiper-2 .swiper-prev",
        },
      },
      carouselSetting3: {
        ...carouselSetting1,
        slidesPerView: 5,
        navigation: {
          nextEl: ".new .swiper-3 .swiper-next",
          prevEl: ".new .swiper-3 .swiper-prev",
        },
      },
      carouselSetting4: {
        ...carouselSetting1,
        slidesPerView: 5,
        navigation: {
          nextEl: ".new .swiper-4 .swiper-next",
          prevEl: ".new .swiper-4 .swiper-prev",
        },
      },
      carouselSetting5: {
        ...carouselSetting1,
        slidesPerView: 5,
        navigation: {
          nextEl: ".new .swiper-5 .swiper-next",
          prevEl: ".new .swiper-5 .swiper-prev",
        },
      },
    };
  },
  components: {
    Tabs,
    Swiper,
    SwiperSlide,
    CertificateCard,
  },
  setup() {
    const onSwiper = (swiper) => {
      return swiper;
    };
    return {
      onSwiper,
      modules: [Navigation, Pagination, Scrollbar],
    };
  },
  computed: {
    isMobile() {
      return window.innerWidth <= 576;
    },
    computers: function () {
      return catFilter(this.products, ["computers", "tablets"]);
    },
    accessories: function () {
      return catFilter(this.products, ["accessories"]);
    },
    camera: function () {
      return catFilter(this.products, ["cameras", "camcorders"]);
    },
    entertainment: function () {
      return catFilter(this.products, ["entertainment"]);
    },
    ratedProducts: function () {
      return attrFilter(this.products, "rated");
    },
  },
};
</script>
<style>
.certificate-card {
  max-height: 321px;
}
</style>
