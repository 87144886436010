import api from "@/repositories/repository";
import { GoodProductEx } from "@/api/models/all";
import { QueryParams, MetaPage, BodyParams } from "../types";
import { Certificate } from "@/api/models/Certificate";

export const fetchProducts = async (
  params: QueryParams
): Promise<
  {
    data: Certificate[];
  } & MetaPage
> => {
  const { data } = await api.get("/api/certs/products", {
    params,
  });
  return data;
};

export const fetchProduct = async (
  params: QueryParams
): Promise<GoodProductEx> => {
  const { data } = await api.get("/api/certs/product", {
    params,
  });
  return data;
};

export const createProduct = async (
  body: BodyParams
): Promise<GoodProductEx> => {
  const { data } = await api.post("/api/certs/make-order", {
    body,
  });
  return data;
};
