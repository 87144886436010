<template>
  <Modal :open="open" @close="onHandleClose">
    <div class="pass-container">
      <div class="pass-wrap remind-pass-wrap">
        <h2 class="mb-8px">Введите 6-значный код и новый пароль</h2>
        <div>
          <span class="pass-input-label new-pass-input-label">
            Одноразовый код отправлен Вам на указанный номер телефона.
          </span>
        </div>
        <div class="new-pass-inputs mb-16px">
          <input
            v-for="(pin, index) in pinCodes"
            :key="index"
            class="new-pass-input"
            autocomplete="off"
            :value="pin"
            @keypress="isNumberStrict"
            @input.stop="(e) => checkPinCode(index, e)"
            inputmode="numeric"
            pattern="[0-9]*"
            maxlength="1"
          />
        </div>

        <form class="w-full" @submit.prevent="onHandleSubmit">
          <ErrorMsg
            v-if="errorMessage"
            :errorMsg="errorMessage"
            @clear="clearErrorMsg"
          />
          <PasswordInput
            hint="Придумайте пароль (Не менее 7 символов, цифр — не менее 1, строчных латинских букв — не менее
      1, прописных латинских букв — не менее 1)"
            placeholder="Пароль"
            class="mb-16px"
            :isValidPassword="isValidPassword"
            v-model:modal-value="password"
          />
          <PasswordInput
            hint="Повторите пароль"
            placeholder="Пароль"
            :isValidPassword="isValidRepeatPassword"
            :enabled-validation="false"
            v-model:modal-value="repeatPassword"
          />
          <div v-if="!isSamePassword" class="password-error">
            Пароли должны совпадать.
          </div>
          <div class="mb-32px"></div>
          <AuthBtn title="Готово" :disabled="disabledRecovery" type="submit" />
          <div class="link-sign-in">
            Есть аккаунт? <span @click="onLogin">Вход</span>
          </div>
        </form>
      </div>
    </div>
  </Modal>
</template>

<script setup lang="ts">
import { defineProps, toRefs, defineEmits, ref, computed } from "vue";
import { isNumberStrict } from "@/utilities/number";
import Modal from "@/components/elements/Modal.vue";
import PasswordInput from "@/components/elements/inputs/PasswordInput.vue";
import AuthBtn from "@/components/elements/buttons/AuthBtn.vue";
import ErrorMsg from "@/components/elements/form/ErrorMsg.vue";
import { useInput } from "@/composables/useInput";

const props = defineProps<{
  open: boolean;
  data: {
    type: "phone" | "guid";
    value: string;
  } | null;
}>();
const { open } = toRefs(props);
const pinCodes = ref(Array(6).fill(""));
const clearPincodes = () => {
  pinCodes.value = Array(6).fill("");
};

const {
  isValidPassword,
  isValidRepeatPassword,
  password,
  repeatPassword,
  clearInputs,
  clearErrorMsg,
  errorMessage,
} = useInput("code");

const $emit = defineEmits(["close", "showLogin", "sendRecoveryPassword"]);

const onLogin = () => {
  $emit("showLogin");
  clearInputs();
};

const onHandleClose = () => {
  clearInputs();
  clearPincodes();
  $emit("close");
};

const checkPinCode = (index: number, event: Event) => {
  const target = event.target as HTMLInputElement;
  const value = target.value.trim().replace(/[\D+]/g, ""); // Get the value of the input, remove non-digit characters
  if (value.length > 1) {
    target.value = value[0]; // Ensure only one character is in the input
  }
  pinCodes.value[index] = target.value; // Update the corresponding value in the array

  const prevEl = target.previousElementSibling as HTMLInputElement; // Previous sibling element
  const nextEl = target.nextElementSibling as HTMLInputElement; // Next sibling element

  if (value.length < 1 && prevEl) {
    prevEl.focus(); // Focus previous input if current input is empty
  } else if (value.length === 1 && nextEl) {
    nextEl.focus(); // Focus next input if current input has one character
  }
};

const arePinCodesFilled = computed(() => {
  return pinCodes.value.every((pin) => pin !== "");
});

const disabledRecovery = computed(() => {
  return (
    !arePinCodesFilled.value ||
    !password.value ||
    !repeatPassword.value ||
    !isValidPassword.value ||
    !isValidRepeatPassword.value ||
    !isSamePassword.value
  );
});

const isSamePassword = computed(() => {
  if (!isValidPassword.value || !isValidRepeatPassword.value) {
    return true;
  }
  return password.value === repeatPassword.value;
});

const onHandleSubmit = () => {
  $emit(
    "sendRecoveryPassword",
    {
      phone: props.data?.value,
      code: pinCodes.value.join(""),
      newPassword: password.value,
      newPasswordConfirm: repeatPassword.value,
    },
    onHandleClose
  );
};
</script>

<style>
.pass-container {
  max-width: fit-content;
  margin-inline: auto;
}
.pass-wrap {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
}
.pass-wrap h2 {
  margin-bottom: 40px;
  font-size: 24px;
  font-family: Gilroy-Medium !important;
  font-weight: 500;
  line-height: 100%;
  letter-spacing: 0;
  color: var(--text_color);
}
.pass-wrap label {
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
}
.pass-wrap label:not(:last-child) {
  margin-bottom: 16px;
}
.pass-wrap input {
  height: 40px;
  width: 100%;
  padding: 12px 16px;
  border-radius: var(--bdrs-7);
  border: var(--border-grey);
  font-size: 16px;
  font-family: Gilroy-Regular !important;
  font-weight: 400;
  line-height: 100%;
  color: var(--text_color);
}
.pass-wrap input::placeholder {
  font-size: 16px;
  font-family: Gilroy-Regular !important;
  font-weight: 400;
  line-height: 100%;
  color: var(--grey-79);
  transition: outline 0.2s;
}
.pass-wrap input:focus {
  outline: 1px solid var(--primary_bg);
}
.pass-input-label {
  text-align: start;
  margin-bottom: 4px;
  font-size: 12px;
  font-family: Gilroy-Regular !important;
  font-weight: 400;
  line-height: 100%;
  color: var(--grey-79);
}

.remind-pass-wrap {
  min-width: 376px;
  max-width: 376px;
}

.remind-pass-wrap a {
  display: flex;
  margin-top: -17px;
}

.remind-pass-wrap h2 {
  margin-bottom: 40px;
}

.remind-pass-wrap form {
  max-width: 281px;
}

.remind-pass-wrap input::placeholder {
  color: #c8c8c8;
}

.remind-pass-switch-wrap {
  display: flex;
  gap: 27px;
  margin-bottom: 55px;
}

.remind-pass-switch {
  display: flex;
  align-items: center;
  gap: 9px;
  padding: 0;
  text-wrap: nowrap;
  font-size: 14px;
  font-family: Gilroy-Regular !important;
  font-weight: 400;
  line-height: 100%;
  color: var(--grey-79);
  transition: color 0.2s;
}

.remind-pass-switch_primary,
.remind-pass-switch:hover {
  color: var(--primary_bg);
}

.remind-pass-input-label {
  margin-top: 7px;
  margin-bottom: 0;
  text-align: center;
  line-height: 120%;
}

.pass-input-label_ls {
  letter-spacing: -0.3px;
  margin-bottom: 8px;
}

.remind-pass-switch-wrap_mb {
  margin-bottom: 51px;
}

@media (max-width: 576px) {
  .remind-pass-wrap {
    margin-top: 15px;
    margin-bottom: 46px;
    min-width: auto !important;
    max-width: auto !important;
    width: 100%;
  }
}

@media (max-width: 480px) {
  .new-pass-wrap {
    max-width: 100%;
    padding-inline: 16px;
  }

  .new-pass-inputs {
    gap: 11px;
    max-width: 286px;
    margin-inline: auto;
    justify-content: initial;
  }

  .remind-pass-switch {
    flex-direction: column;
  }

  .remind-pass-wrap h2 {
    font-size: 22px;
    margin-bottom: 30px;
  }

  .remind-pass-switch-wrap {
    gap: 32px;
  }

  .remind-pass-switch-wrap_mb {
    margin-bottom: 42px;
  }

  .remind-pass-switch-wrap_member {
    margin-bottom: 44px;
  }

  .remind-pass-wrap form {
    max-width: 327px;
  }
}

.remind-pass-switch:hover svg path {
  transition: stroke 0.2s;
}

.remind-pass-switch_primary svg path,
.remind-pass-switch:hover svg path {
  stroke: var(--primary_bg);
}

.forget-password {
  color: var(--primary_bg);
  text-align: center;
  font-family: Gilroy;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  width: 100%;
  text-align: left;
  padding: 0;
}

.link-sign-in {
  color: var(--grey-79);
  font-family: Gilroy;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  margin-top: 40px;

  span {
    display: inline-block;
    color: var(--text_color);
    position: relative;
    cursor: pointer;

    &::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: -2px; /* Adjust as needed */
      width: 100%;
      height: 1px;
      background-color: var(--text_color); /* Adjust as needed */
    }
  }
}

.new-pass-wrap {
  margin-block: 52px;
  max-width: 282px;
}
.new-pass-wrap h2 {
  margin-bottom: 32px;
  max-width: 255px;
}
.new-pass-wrap label {
  position: relative;
}
.new-pass-wrap input {
  padding-right: 40px;
}
.new-pass-input-wrap {
  position: relative;
  width: 100%;
}
.new-pass-eye {
  position: absolute;
  top: 50%;
  right: 15px;
  display: none;
  padding: 0;
  transform: translateY(-50%);
}
.new-pass-input-label {
  letter-spacing: -0.2px;
}
.new-pass-btn {
  margin-top: 31px;
}
input.input--active + .new-pass-eye {
  display: flex;
}
.new-pass-eye__eye-close,
.new-pass-input-wrap .new-pass-eye.show-password--active .new-pass-eye__eye {
  display: none;
}
.new-pass-input-wrap
  .new-pass-eye.show-password--active
  .new-pass-eye__eye-close {
  display: block;
}
input.input--active {
  background-color: var(--light_gray);
}
/* поле для пин-кода  */
.new-pass-inputs {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  margin-bottom: 32px;
  max-width: 283px;
}
.new-pass-inputs input.new-pass-input {
  padding-inline: 12px;
  text-align: center;
}
.new-pass-input {
  width: 38px;
}

.password-error {
  color: #ff6666;
  font-size: 12px;
  line-height: 100%;
}

.pass-input-label {
  text-align: start;
  margin-bottom: 4px;
  font-size: 12px;
  font-family: Gilroy-Regular !important;
  font-weight: 400;
  line-height: 100%;
  color: var(--grey-79);
}
</style>
