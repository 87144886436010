<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.1667 4.16672H15.8333C16.75 4.16672 17.5 4.91672 17.5 5.83338V14.1667C17.5 15.0834 16.75 15.8334 15.8333 15.8334H14.1667M10.8333 4.25005V15.75C10.8333 16.8334 9.83333 17.6667 8.83333 17.5L3.83333 16.4167C3.08333 16.2501 2.5 15.5 2.5 14.6667V5.33338C2.5 4.50005 3.08333 3.75005 3.83333 3.58338L8.83333 2.50005C9.83333 2.33338 10.8333 3.16672 10.8333 4.25005Z"
      stroke="black"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.5 9.58337V10.4167"
      stroke="black"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
