import api from "@/repositories/repository";
import { GoodProduct, GoodProductEx } from "@/api/models/all";
import { QueryParams, MetaPage, BodyParams, DeliveryVariants } from "../types";

export const fetchProducts = async (
  params: QueryParams
): Promise<
  {
    data: GoodProduct[];
  } & MetaPage
> => {
  const { data } = await api.get("/api/goods/products", {
    params,
  });
  return data;
};

export const fetchProduct = async (
  params: QueryParams
): Promise<GoodProductEx> => {
  const { data } = await api.get("/api/goods/product", {
    params,
  });
  return data;
};

export const createProduct = async (
  body: BodyParams
): Promise<GoodProductEx> => {
  const { data } = await api.post("/api/goods/make-order", {
    body,
  });
  return data;
};

export const fetchDeliveryVariants = async (
  body: BodyParams
): Promise<DeliveryVariants[]> => {
  const { data } = await api.post(
    "/api/goods/delivery-variants",
    {},
    {
      params: body,
    }
  );
  return data;
};
